@font-face {
  font-family: 'Gotham', sans-serif;
  src: url(https://fonts.cdnfonts.com/css/gotham);
}

:root {
  --background-color: #fff;
  --main-color: #000;
  --main-dark-color: #000000a0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  line-height: 1.1;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 0.1rem;
  margin-top: 0.2rem;
  line-height: 1.05;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 0.05rem;
  margin-top: 0.2rem;
  line-height: 1.05;
}

body {
  font-family: arial;
  font-size: 0.9rem;
  color: var(--main-color);
}

.round-corner {
  border-radius: 8px !important;
}

.page-content {
  margin-left: -8px;
  margin-top: 12px;
  width: 100%;
}

.white-bg {
  background-color: #ffffff;
}

.dialogText {
  font-size: 0.9rem !important;
}

.side-bar {
  background-color: var(--background-color) !important;
}
.side-bar-text {
  color: var(--main-color);
}

.side-bar-button {
  border: var(--main-color) solid 1px !important;
  border-radius: 1.4rem !important;
  padding-left: 0.7rem !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin: 0.5rem !important;
  min-height: 2.4rem;
}
.side-bar-icon {
  color: var(--main-color);
}
.side-bar-button:hover {
  background-color: var(--main-color) !important;
}

.side-bar-button:hover > .side-bar-icon {
  color: #fff;
}

.side-bar-button:hover > .side-bar-text {
  color: #fff;
}

.bottomBar {
  position: sticky;
  bottom: 0;
}

/* Login */
.login-card {
  max-width: 300px;
  min-width: 256px;
  margin: 10px auto auto auto;
  padding: 10px;
  padding-bottom: 16px;
  background-color: #000;
  color: var(--main-color);
  border: 1px solid #fff;
  border-radius: 5px;
}

/* Form elements */
.MuiTextField-root label[data-shrink='false'] {
  color: var(--main-dark-color);
}
.MuiTextField-root label[data-shrink='true'] {
  color: var(--main-color);
}

/* Form elements */
.login-card .MuiTextField-root label[data-shrink='false'] {
  color: #aaaaaaaa;
}
.login-card .MuiTextField-root label[data-shrink='true'] {
  color: #fff;
}

.guest {
  background-image: linear-gradient(to right, #000, #a90000);
  background-color: #a90000;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #000;
  border-radius: 10px;
}
/* Form elements */
.guest .MuiTextField-root label[data-shrink='false'] {
  color: #aaaaaaaa;
}

.guest .MuiTextField-root label[data-shrink='true'] {
  color: #fff;
}

.guest-list--1 {
  background-color: #fff;
}
.guest-list--2 {
  background-color: #aaa;
}
.guest-list--3 {
  background-color: #fae;
}
.guest-list--4 {
  background-color: #f9a;
}
.guest-list--5 {
  background-color: #afa;
}
